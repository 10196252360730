<template>
  <div>
    <div class="broker-recommendation">
      <!-- <p v-if="brokerRecommendation.noPreRenewalDeclaration">
        All quotations obtained were based on disclosure matters (e.g. defects,
        fire orders, Works over $500,000, WHS issues, Asbestos, presence of ACP
        and known circumstances that may lead to an office bearer's liability
        claim. If this is incorrect, please contact our office immediately as
        pricing may change.
      </p> -->
      The team at Collective Insurance Brokers trust this report is of value and
      look forward to receiving your instructions and or comments.
      <br />

      <div v-if="!isReadOnly">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="brokerRecommendation.renewWithCheapestInsurer"
          />
          <label class="form-check-label">
            <strong
              >Include this section (and amend if needed) if CIB advice is to
              renew with the cheapest insurer</strong
            >
          </label>
        </div>

        <textarea
          :class="
            !brokerRecommendation.renewWithCheapestInsurer
              ? 'readonly-textarea'
              : ''
          "
          v-model="brokerRecommendation.renewWithCheapestInsurerDetails"
          :readonly="!brokerRecommendation.renewWithCheapestInsurer"
          class="w-100"
          style="border: 1px solid gray"
        ></textarea>
      </div>
      <p v-if="isReadOnly && brokerRecommendation.renewWithCheapestInsurer">
        <br />
        {{ brokerRecommendation.renewWithCheapestInsurerDetails }}
        <br />
      </p>
      <div v-if="!isReadOnly">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="
              brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
            "
          />
          <label class="form-check-label">
            <strong
              >Include this section (and amend if needed) if CIB advice is to
              renew with insurer who is not the cheapest but has competitive
              excesses</strong
            >
          </label>
        </div>
        <textarea
          :class="
            !brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
              ? 'readonly-textarea'
              : ''
          "
          v-model="
            brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails
          "
          :readonly="
            !brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          "
          class="w-100"
          style="border: 1px solid gray"
        >
        </textarea>
      </div>
      <p>
        {{
          isReadOnly &&
          brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
            ? brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails
            : ""
        }}
      </p>
      <div class="broker-reco-img pt-4 pb-4">
        <img class="img-fluid w-100" src="../images/up-date.jpg" alt="" />
      </div>
      <p class="mb-3">
        Any advice we provide in this report is of general advice only. Before
        acting on advice within this report, you should consider its
        appropriateness considering your objectives, needs and financial
        situation.
      </p>
      <p class="mb-3">
        If personal advice is required, we welcome the opportunity to discuss
        and provide a tailored insurance programme to suit the committee's
        specific requirements.
      </p>
      <p class="mb-3">
        Once our recommendation has been reviewed in conjunction of the insurers
        Product Disclosure Statement, please advise instructions on which
        insurer you wish to place cover prior to the renewal date. If for any
        reason we do not receive instructions prior to the current policy
        expiry, our office will proceed to place your insurances as per our
        recommendation.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrokerRecommendation",
  props: {
    brokerRecommendation: Object,
    isReadOnly: Boolean,
  },
};
</script>
