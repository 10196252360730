<template>
  <div id="v-pills-insurer-product">
    <div class="insurance-pds">
      <p class="mb-4">
        Please review all quotations and schedules together with the relevant
        insurer's Product Disclosure Statement. For your ease of reference, we
        have provided below direct links to each insurer's PDS.
      </p>
      <h6 class="p-2 bg-orange text-white text-uppercase">
        {{ buildingType }} STRATA
      </h6>
      <div class="div insurance-logo row mt-3 disclosure_statement">
        <div class="column" v-for="(item, index) in insurers" :key="index">
          <a class="d-block" :href="item.pdsLink" target="_blank">
            <div class="mx-0 row w-100 ins-logo p-3">
              <div class="col-7 card-img">
                <img
                  v-if="item.logo"
                  class="img-fluid"
                  :src="item.logo"
                  :alt="item.name"
                />
                <span v-if="!item.logo" class="font-weight-bold text-black">{{
                  item.name
                }}</span>
              </div>
              <div class="col-5 card-body p-0">
                <p class="text-right mb-0">
                  Click here to <br />
                  view the PDS
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductDisclosureStatement",
  props: {
    insurers: Array,
    buildingType: String,
  },
};
</script>
