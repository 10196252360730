<template>
  <div id="v-pills-fire-service">
    <h6 class="font-weight-bold">Fire Service Levy</h6>
    <div class="fire-service">
      <p class="mb-3">
        Insurance companies selling certain lines of general insurance policies
        are required to provide the NSW Government with funding for the fire and
        emergency services each financial year. In the financial year 2018-19
        insurers were required to contribute $785 million towards the funding of
        the fire and emergency services in NSW. Services funded are
        <a target="_blank" href="https://www.fire.nsw.gov.au">
          Fire and Rescue NSW,
        </a>
        <a target="_blank" href="https://www.rfs.nsw.gov.au">
          NSW Rural Fire Service,
        </a>
        and
        <a target="_blank" href="https://www.ses.nsw.gov.au">
          NSW State Emergency Services.</a
        >
      </p>
      <p class="mb-3">
        Insurers generally reclaim their contribution amount paid from their
        customers by charging an Emergency Services Levy (ESL) on policy
        premiums in relevant insurance classes.
      </p>
      <p class="mb-3">
        The amount of ESL charged is set by each insurance company, which means
        that different companies may charge different rates of ESL for similar
        insurance policies.
      </p>
      <p class="mb-3">
        Below is a table indicating the Fire Service Levy charged last year
        opposed to our recommended renewal premium.
      </p>
      <table class="w-100 execute-summary-table my-md-5 my-4">
        <tr>
          <!-- <th>&nbsp;</th> -->
          <th class="text-center">Expiring policy</th>
          <th class="text-center">Recommended renewal</th>
          <th class="text-center">Change</th>
        </tr>
        <tr>
          <!-- <td>Fire service levy charge</td> -->
          <td class="text-center">
            <span v-if="fees.K_PriorYearEslLevies">
              {{
                fees.K_PriorYearEslLevies
                  | currency("$", ",", 2, ".", "front", false)
              }}
            </span>
          </td>
          <td class="text-center">
            <span v-if="fees.K_CurrentYearEslLevies">
              {{
                fees.K_CurrentYearEslLevies
                  | currency("$", ",", 2, ".", "front", false)
              }}
            </span>
          </td>
          <td class="text-center">
            <span> {{ fees.eslLeviesChangePercent }}% </span>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "FireServiceLevy",

  props: {
    fees: Object,
  },
};
</script>
