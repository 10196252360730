<template>
  <div>
    <div class="quotation-schedule">
      <table class="w-100">
        <thead>
          <tr>
            <td style="background-color: #ffffff !important"></td>
            <td style="background-color: #ffffff !important" class="text-center"
              v-for="(item, index) in quotationSchedule.insurers" :key="index">
              <h4 v-if="item.recommended" style="color: #f99c34">
                Recommended Insurer
              </h4>
            </td>
          </tr>
          <tr>
            <th>Cover</th>
            <th class="text-center" v-for="(item, index) in quotationSchedule.insurers" :key="index"
              :class="item.recommended ? 'recommended-th' : ''">
              <!--              <img v-if="item.logo"-->
              <!--                style="float: left; height: 40px; width: 40px; background: white"-->
              <!--                :src="item.logo"-->
              <!--                :alt="item.name"-->
              <!--              />-->
              <span>
                {{ item.name }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in quotationSchedule.sumInsuredData" :key="item.apiKey">
            <td>{{ item.displayName }}</td>
            <td class="text-right" v-for="(col, colIndex) in item.data" :key="colIndex"
              :class="colIndex === recommendedIndex ? 'recommended' : ''">
              <label v-if="item.type === 'Currency'">{{
                col | currency("$ ", ",", 0, ".", "front", false)
                }}</label>
              <label v-else>{{ col }}</label>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-uppercase text-sky font-weight-bold">
              EXCESS
            </td>
          </tr>
          <tr v-for="item in quotationSchedule.excessData" :key="item.apiKey">
            <td>{{ item.displayName }}</td>
            <td class="text-right" v-for="(col, colIndex) in item.data" :key="colIndex"
              :class="colIndex === recommendedIndex ? 'recommended' : ''">
              <label>{{
                col | currency("$ ", ",", 0, ".", "front", false)
                }}</label>
            </td>
          </tr>
          <tr>
            <td colspan="4" class="text-uppercase text-sky font-weight-bold">
              PREMIUM
            </td>
          </tr>
          <tr v-for="item in quotationSchedule.financialData" :key="item.apiKey">
            <td>{{ item.displayName }}</td>
            <td class="text-right" v-for="(col, colIndex) in item.data" :key="colIndex"
              :class="colIndex === recommendedIndex ? 'recommended' : ''">
              <label>{{
                formatValue(col, item.type)
                }}</label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuotationSchedule",
  props: {
    quotationSchedule: Object,
  },
  data() {
    return {
      recommendedIndex: -1,
    };
  },
  created() {
    for (var i = 0; i < this.quotationSchedule.insurers.length; i++) {
      if (this.quotationSchedule.insurers[i].recommended) {
        this.recommendedIndex = i;
      }
    }
  },
  methods: {
    formatValue(col, type) {
      if (type === 'currency') {
        return this.$options.filters.currency(col, "$ ", ",", 2, ".", "front", false);
      }
      return col;
    }
  }
};
</script>
<style></style>
