var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"broker-recommendation"},[_vm._v(" The team at Collective Insurance Brokers trust this report is of value and look forward to receiving your instructions and or comments. "),_c('br'),(!_vm.isReadOnly)?_c('div',[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.brokerRecommendation.renewWithCheapestInsurer),expression:"brokerRecommendation.renewWithCheapestInsurer"}],staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.brokerRecommendation.renewWithCheapestInsurer)?_vm._i(_vm.brokerRecommendation.renewWithCheapestInsurer,null)>-1:(_vm.brokerRecommendation.renewWithCheapestInsurer)},on:{"change":function($event){var $$a=_vm.brokerRecommendation.renewWithCheapestInsurer,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.brokerRecommendation, "renewWithCheapestInsurer", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.brokerRecommendation, "renewWithCheapestInsurer", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.brokerRecommendation, "renewWithCheapestInsurer", $$c)}}}}),_vm._m(0)]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.brokerRecommendation.renewWithCheapestInsurerDetails),expression:"brokerRecommendation.renewWithCheapestInsurerDetails"}],staticClass:"w-100",class:!_vm.brokerRecommendation.renewWithCheapestInsurer
            ? 'readonly-textarea'
            : '',staticStyle:{"border":"1px solid gray"},attrs:{"readonly":!_vm.brokerRecommendation.renewWithCheapestInsurer},domProps:{"value":(_vm.brokerRecommendation.renewWithCheapestInsurerDetails)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.brokerRecommendation, "renewWithCheapestInsurerDetails", $event.target.value)}}})]):_vm._e(),(_vm.isReadOnly && _vm.brokerRecommendation.renewWithCheapestInsurer)?_c('p',[_c('br'),_vm._v(" "+_vm._s(_vm.brokerRecommendation.renewWithCheapestInsurerDetails)+" "),_c('br')]):_vm._e(),(!_vm.isReadOnly)?_c('div',[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
            _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          ),expression:"\n            brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive\n          "}],staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
            _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          )?_vm._i(
            _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          ,null)>-1:(
            _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          )},on:{"change":function($event){var $$a=
            _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
          ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.brokerRecommendation, "renewWithInsurerWhoIsNotTheCheapestButHasCompetitive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.brokerRecommendation, "renewWithInsurerWhoIsNotTheCheapestButHasCompetitive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.brokerRecommendation, "renewWithInsurerWhoIsNotTheCheapestButHasCompetitive", $$c)}}}}),_vm._m(1)]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(
          _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails
        ),expression:"\n          brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails\n        "}],staticClass:"w-100",class:!_vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive
            ? 'readonly-textarea'
            : '',staticStyle:{"border":"1px solid gray"},attrs:{"readonly":!_vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive},domProps:{"value":(
          _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails
        )},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.brokerRecommendation, "renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails", $event.target.value)}}})]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.isReadOnly && _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitive ? _vm.brokerRecommendation.renewWithInsurerWhoIsNotTheCheapestButHasCompetitiveDetails : "")+" ")]),_vm._m(2),_c('p',{staticClass:"mb-3"},[_vm._v(" Any advice we provide in this report is of general advice only. Before acting on advice within this report, you should consider its appropriateness considering your objectives, needs and financial situation. ")]),_c('p',{staticClass:"mb-3"},[_vm._v(" If personal advice is required, we welcome the opportunity to discuss and provide a tailored insurance programme to suit the committee's specific requirements. ")]),_c('p',{staticClass:"mb-3"},[_vm._v(" Once our recommendation has been reviewed in conjunction of the insurers Product Disclosure Statement, please advise instructions on which insurer you wish to place cover prior to the renewal date. If for any reason we do not receive instructions prior to the current policy expiry, our office will proceed to place your insurances as per our recommendation. ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-check-label"},[_c('strong',[_vm._v("Include this section (and amend if needed) if CIB advice is to renew with the cheapest insurer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"form-check-label"},[_c('strong',[_vm._v("Include this section (and amend if needed) if CIB advice is to renew with insurer who is not the cheapest but has competitive excesses")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broker-reco-img pt-4 pb-4"},[_c('img',{staticClass:"img-fluid w-100",attrs:{"src":require("../images/up-date.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }