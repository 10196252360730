<template>
  <div>
    <div class="policy-recommandation">
      <div class="row">
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_buildingsuminsured.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">Building Valuation</h6>
              <p>
                All State and Territory Strata and Community Title legislation
                requires that a strata building be insured for the complete
                replacement and/or reinstatement value. We recommend a valuation
                for insurance purposes as required by the State and Territory
                Strata and Community Title legislation be undertaken.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_liabilitycover.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">Public Liability</h6>
              <p>
                All State and Territory Strata and Community Title legislation
                requires that a strata building be insured for the complete
                replacement and/or reinstatement value. We recommend a valuation
                for insurance purposes as required by the State and Territory
                Strata and Community Title legislation be undertaken.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_buildingvaluation.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">Office Bearers</h6>
              <p>
                Office Bearers provides cover for the Owners Corporation's
                Office Bearers' should they become legally liable to pay
                compensation for any wrongful act they commit while carrying out
                the functions of their position. Office Bearers Liability is an
                optional cover which can be requested from the insurer. We
                recommend that the Committee purchase as much as they can
                afford.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_catastrophe.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">Catastrophe</h6>
              <p>
                Catastrophe provides cover up to the Sum Insured in the Schedule
                against the sudden escalation in rebuilding costs due to a
                Catastrophe (e.g. an earthquake or cyclone) or other Damage that
                occurs not more than 60 days after the declared event.
                Catastrophe is an optional cover that can be requested from the
                insurer.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_machinery.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">Machinery Breakdown</h6>
              <p>
                The current insurance market provides two types of Machinery
                Breakdown Products:
              </p>
              <ul style="list-style-type: disc; padding-left: 1.5em;">
                <li class="aligned-bullet">General Blanket cover for $100,000</li>
                <li class="aligned-bullet">
                  Separate Engineering policy for all sized motors, covering
                  all equipment equal to the building sum insured
                </li>
              </ul>
              <p class="mb-3">
                Please note that the General Blanket cover automatically
                includes Fusion for motors, with some insurers limiting the
                cover due to the capacity of the motors. An Engineering policy
                however covers the machinery, electronic or electrical
                breakdown. The policy extends to cover:
              </p>
              <ul style="list-style-type: disc; padding-left: 1.5em;">
                <li class="aligned-bullet">Physical Damage</li>
                <li class="aligned-bullet">Expediting Expenses</li>
                <li class="aligned-bullet">Loss of Rental Income & Temporary Accommodation</li>
                <li>Service Interruption</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card border-0 mb-4">
            <div class="card-img mb-2">
              <img src="../images/icon_security.png" alt="" />
            </div>
            <div class="card-body p-0">
              <h6 class="font-weight-bold">
                Examples of Equipment items covered in an apartment building:
              </h6>
              <ul style="list-style-type: disc; padding-left: 1.5em;">
                <li class="aligned-bullet">Air Conditioning and Heating</li>
                <li class="aligned-bullet">Lifts and Elevators</li>
                <li class="aligned-bullet">Cooling Towers</li>
                <li class="aligned-bullet">
                  Electrical switchboards, cabling and Emergency Generators
                </li>
                <li class="aligned-bullet">Audio and visual equipment</li>
                <li class="aligned-bullet">Security Systems</li>
                <li class="aligned-bullet">Building Automation / Management Systems</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PolicyRecommendations",
};
</script>
<style>
    .aligned-bullet {
      white-space: normal;
      overflow-wrap: break-word;
    }
</style>