<template>
  <div>
    <div class="claim-years">
      <table
        v-if="Array.isArray(claimsHistory) && claimsHistory.length > 0"
        class="w-100 execute-summary-table my-md-5 my-4"
      >
        <tr>
          <th>Date of loss</th>
          <th>Claim type</th>
          <th>Status</th>
          <th>Amount paid</th>
        </tr>
        <tr v-for="(item, index) in claimsHistory" :key="index">
          <td>
            <span v-if="item.K_DateOfLoss">
              {{
                item.K_DateOfLoss
                  | dateParse("YYYY-MM-DD")
                  | dateFormat("DD/MM/YYYY")
              }}
            </span>
          </td>
          <td>{{ item.K_TypeOfClaim }}</td>
          <td>{{ item.K_Status }}</td>
          <td>
            <button
              variant="link"
              :id="`popover-${index}`"
              href="#"
              tabindex="0"
            >
              {{
                item.K_TotalClaimCost
                  | currency("$", ",", 2, ".", "front", false)
              }}
            </button>
            <b-popover :target="`popover-${index}`" triggers="focus">
              <template #title>{{ item.K_TypeOfClaim }}</template>
              {{ item.K_DescriptionOfLossDamage }}
            </b-popover>
          </td>
        </tr>
      </table>
      <div
        v-if="!Array.isArray(claimsHistory) || claimsHistory.length === 0"
        class="mt-10 mb-10"
      >
        No claims have been reported in the past 5 years.
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ClaimsHistory",
  props: {
    claimsHistory: Array,
  },
};
</script>
