<template>
  <div id="v-pills-execute-summary ">
    <div>
      <div v-if="!isReadOnly">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="executiveSummary.recentlyAppointed"
          />
          <label class="form-check-label">
            <strong
              >Include this section (and amend if needed) if CIB was recently
              appointed as the Broker</strong
            >
          </label>
        </div>
        <textarea
          v-model="executiveSummary.recentlyAppointedDetails"
          :class="
            !executiveSummary.recentlyAppointed ? 'readonly-textarea' : ''
          "
          :readonly="!executiveSummary.recentlyAppointed"
          class="w-100 textarea-pdf"
          style="border: 1px solid gray"
          name=""
          cols="30"
          rows="3"
        ></textarea>
      </div>
      <p>
        <span v-if="isReadOnly && executiveSummary.recentlyAppointed">
          {{ executiveSummary.recentlyAppointedDetails }}
          <br />
        </span>

        <br />
        Another year of record-breaking local catastrophes coupled with the
        COVID-19 pandemic, low interest rates and increasing claim costs have
        formed a perfect storm within the insurance industry. We are currently
        seeing insurers apply price increases between 15-30% on "clean"
        renewals.
      </p>
      <p>
        Strata insurers are also changing their underwriting guidelines with a
        strong focus on minimising risk. It is now increasingly difficult to
        obtain quotations for properties with excessive claims loss history,
        building defects or combustible cladding. Failure to rectify defects in
        a timely manner may also result in the insurer declining to offer
        renewal terms.
        <br />

        <span
          v-if="
            isReadOnly && executiveSummary.premiumIncreaseWithCurrentInsurer
          "
        >
          <br />
          {{ executiveSummary.premiumIncreaseWithCurrentInsurerDetails }}
        </span>
      </p>
      <div v-if="!isReadOnly">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="executiveSummary.premiumIncreaseWithCurrentInsurer"
          />
          <label class="form-check-label">
            <strong
              >Include this section (and amend if needed) if premium has
              increased with current insurer due to issues/claims</strong
            >
          </label>
        </div>
        <textarea
          v-model="executiveSummary.premiumIncreaseWithCurrentInsurerDetails"
          :class="
            !executiveSummary.premiumIncreaseWithCurrentInsurer
              ? 'readonly-textarea'
              : ''
          "
          :readonly="!executiveSummary.premiumIncreaseWithCurrentInsurer"
          class="w-100 textarea-pdf"
          style="border: 1px solid gray"
          name=""
          cols="30"
          rows="3"
        ></textarea>
      </div>
    </div>

    <span
      v-if="isReadOnly && executiveSummary.valuationReceived
        "
    >
      {{ executiveSummary.valuationReceivedDetails }}
      <br /><br />
    </span>
    <div v-if="!isReadOnly">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="executiveSummary.valuationReceived"
        />
        <label class="form-check-label">
          <strong
            >Include this section (and amend if needed) if the most recent
            Building valuation was received</strong
          >
        </label>
      </div>

      <textarea
        :class="!executiveSummary.valuationReceived ? 'readonly-textarea' : ''"
        v-model="executiveSummary.valuationReceivedDetails"
        :readonly="!executiveSummary.valuationReceived"
        class="w-100 textarea-pdf"
        style="border: 1px solid gray"
        name=""
        cols="30"
        rows="3"
      ></textarea>
    </div>

     <span
        v-if="isReadOnly && executiveSummary.instructionsFromStrataManager
          "
      >
        {{ executiveSummary.instructionsFromStrataManagerDetails }}
        <br /><br />
      </span>
    <div v-if="!isReadOnly" class="mb-4">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="executiveSummary.instructionsFromStrataManager"
        />
        <label class="form-check-label">
          <strong
            >Include this section (and amend if needed) if building valuation not received, but the Strata Manager has instructed to increase Building Sum Insured</strong
          >
        </label>
      </div>

      <textarea
        :class="!executiveSummary.instructionsFromStrataManager ? 'readonly-textarea' : ''"
        v-model="executiveSummary.instructionsFromStrataManagerDetails"
        :readonly="!executiveSummary.instructionsFromStrataManager"
        class="w-100 textarea-pdf"
        style="border: 1px solid gray"
        name=""
        cols="30"
        rows="3"
      ></textarea>
    </div>
    <!-- <p>
      <span v-if="isReadOnly && executiveSummary.valuationReceived">
        {{ executiveSummary.valuationReceivedDetails }}
        <br /><br />
      </span>

      Following instructions received from your strata manager, your Building
      Sum Insured has increased from
      {{
        renewalSummary.buildingSumInsured["current"]
          | currency("$", ",", 0, ".", "front", false)
      }}
      to
      {{
        renewalSummary.buildingSumInsured["renewal"]
          | currency("$", ",", 0, ".", "front", false)
      }}.
    </p> -->
    <p>
      Your existing insurer {{ renewalSummary["currentInsurer"] }} has offered a
      renewal premium of
      {{
        renewalSummary.totalPremiumPayable.renewal["total"]
          | currency("$", ",", 2, ".", "front", false)
      }}
      compared to
      {{
        renewalSummary.totalPremiumPayable.current["total"]
          | currency("$", ",", 2, ".", "front", false)
      }}

      paid for the expiring {{ executiveSummary.policyPeriodMonths }} month
      policy.
    </p>
    <p>
      To ensure your terms were competitive, we conducted a marketing exercise
      with our panel of specialist insurers and tabled below an overview of our
      results.
    </p>

    <table class="w-100 textarea-pdf execute-summary-table my-4 my-4">
      <tr>
        <th>Insurer</th>
        <th class="text-right">Building Sum Insured</th>
        <th class="text-right">Standard Excess</th>
        <th class="text-right">Premium Payable</th>
        <th class="text-right">Period</th>
      </tr>
      <tr v-for="(quote, index) in executiveSummary.allQuotes" :key="index">
        <td>{{ quote.insurer }}</td>

        <td
          class="text-center"
          v-if="quote.insurerOutcome !== 'Quote Received'"
          colspan="4"
        >
          {{ quote.insurerOutcome }} {{ quote.declineReason ? ' - ' + quote.declineReason : '' }}  
        </td>

        <td class="text-right" v-if="quote.insurerOutcome === 'Quote Received'">
          {{
            quote.buildingSumInsured
              | currency("$", ",", 0, ".", "front", false)
          }}
        </td>
        <td class="text-right" v-if="quote.insurerOutcome === 'Quote Received'">
          {{
            quote.standardExcess | currency("$", ",", 0, ".", "front", false)
          }}
        </td>
        <td class="text-right" v-if="quote.insurerOutcome === 'Quote Received'">
          {{
            quote.premiumPayable | currency("$", ",", 2, ".", "front", false)
          }}
        </td>
        <td class="text-right" v-if="quote.insurerOutcome === 'Quote Received'">
          {{ quote.duration }}
        </td>
      </tr>
    </table>
    <p>
      A detailed schedule highlighting your policy limits, excesses of the
      {{ executiveSummary.allQuotes.length }}
      most competitive insurers is outlined in section below. Please review all
      quotations and schedules together with the relevant insurer's Product
      Disclosure Statement (direct links are available in below section).
    </p>

    
    <span
      v-if="isReadOnly && executiveSummary.quotationsBasedOnDeclaration
        "
    >
      {{ executiveSummary.quotationsBasedOnDeclarationDetails }}
      <br /><br />
    </span>
    <div v-if="!isReadOnly" class="mb-4">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="executiveSummary.quotationsBasedOnDeclaration"
        />
        <label class="form-check-label">
          <strong
            >Include this section (and amend if needed) if completed Declaration has been received.</strong
          >
        </label>
      </div>

      <textarea
        :class="!executiveSummary.quotationsBasedOnDeclaration ? 'readonly-textarea' : ''"
        v-model="executiveSummary.quotationsBasedOnDeclarationDetails"
        :readonly="!executiveSummary.quotationsBasedOnDeclaration"
        class="w-100 textarea-pdf"
        style="border: 1px solid gray"
        name=""
        cols="30"
        rows="3"
      ></textarea>
    </div>

    <span
      v-if="isReadOnly && executiveSummary.quotationsBasedOnExistingInformation
        "
    >
      {{ executiveSummary.quotationsBaseOnExistingInformationDetails }}
      <br /><br />
    </span>
    <div v-if="!isReadOnly" class="mb-4">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="executiveSummary.quotationsBasedOnExistingInformation"
        />
        <label class="form-check-label">
          <strong
            >Include this section (and amend if needed) if completed Declaration has NOT been received.</strong
          >
        </label>
      </div>

      <textarea
        :class="!executiveSummary.quotationsBasedOnExistingInformation ? 'readonly-textarea' : ''"
        v-model="executiveSummary.quotationsBaseOnExistingInformationDetails"
        :readonly="!executiveSummary.quotationsBasedOnExistingInformation"
        class="w-100 textarea-pdf"
        style="border: 1px solid gray"
        name=""
        cols="30"
        rows="3"
      ></textarea>
    </div>

    <!-- <p>
      All quotations obtained were based on the completed CIB Pre-Renewal
      declaration . If any information requires amendment or additional matters
      need to be disclosed, please contact our office immediately as pricing may
      change.
    </p> -->
  </div>
</template>
<script>
export default {
  name: "ExecutiveSummary",
  props: {
    executiveSummary: Object,
    strataCompany: String,
    renewalSummary: Object,
    isReadOnly: Boolean,
  },
  created() {
    this.executiveSummary.recentlyAppointedDetails =
      this.executiveSummary.recentlyAppointedDetails.replace(
        "{#Strata_Company#}",
        this.strataCompany
      );
    this.executiveSummary.valuationReceivedDetails =
      this.executiveSummary.valuationReceivedDetails.replace(
        "{# OLD BSI# }",
        this.renewalSummary.buildingSumInsured["current"]
      );
    this.executiveSummary.valuationReceivedDetails =
      this.executiveSummary.valuationReceivedDetails.replace(
        "{# NEW BSI# }",
        this.renewalSummary.buildingSumInsured["renewal"]
      );
  },
};
</script>
