<template>
  <div>
    <div class="important-notice mt-15">
      <h5 class="font-weight-bold">Terrorism Levy</h5>
      <p class="mb-3">
        From 1 July 2017, a greater number and wider class of buildings will
        fall within the Australian Reinsurance Pool Corporation (ARPC) scheme
        definition of what constitutes a commercial and/or large scheme
        residential building and will therefore be subject to an ARPC terrorism
        premium
      </p>
      <p class="mb-3">
        From 1 July 2017, a mandatory terrorism premium and cover applies in the
        following circumstances:
      </p>
      <ul style="list-style-type: disc; padding-left: 1.5em;">
        <li class="aligned-bullet">
          The building has a commercial floor space of 20% or greater; or
        </li>
        <li class="aligned-bullet">
          The building (regardless of whether it's used as a commercial or
          residential strata) has a building sum insured of $50M or greater.
        </li>
      </ul>
      <p class="mb-5">
        These changes apply to all renewals and new business policies incepting
        from 1 July 2017. To facilitate transparency and full disclosure of the
        terrorism premium, from 1 July 2017, the insurer policy schedule will
        clearly state any terrorism premiums.
      </p>
      <h5 class="font-weight-bold">How is my terrorism premium calculated?</h5>
      <p>
        The main factors which are considered to calculate the Terrorism premium
        are:
      </p>
      <ul style="list-style-type: disc; padding-left: 1.5em;">
        <li class="aligned-bullet">
          The location of the property (Terrorism tiers can be found via
          <a target="_blank" href="https://arpc.gov.au/our-customers/postcodes">
            APRC website </a>)
        </li>
        <li class="aligned-bullet">The Property sum(s) insured; and</li>
        <li class="aligned-bullet">The Property premium</li>
      </ul>
      <h6 class="font-weight-bold">Office Bearers</h6>
      <p class="mb-4">
        Office Bearers Legal Liability, Government Audit Costs, Appeal Expenses
        and Legal Defence Expenses events are covered on a Claims Made and
        Notified Basis. This means that this Policy only covers claims first
        made against you during the period this Policy is in force and notified
        to the Insurer as soon as practicable in writing while the Policy is in
        force
      </p>
      <h6 class="font-weight-bold">Cladding</h6>
      <p class="mb-3">
        Cladding has been a popular method of wall construction for its
        lightweight, metallic surface which is very cost effective to
        manufacture and to install. However, Aluminium Cladding Panels (ACP) are
        deemed combustible and have become an issue for Insurers. A popular type
        of product is manufactured with a polyethylene core, which is
        essentially a plastic sheet sandwiched between two pieces of aluminium
        sheet. There has been numerous fires and deaths around the world due to
        ACP with the 2016 tragedy of the Grenfell Tower fire in England being
        the largest.
      </p>
      <p class="mb-3">
        Due to additional exposure of ACP, insurers require information when
        providing quotations or renewing existing covers. It is a requirement
        that the insurer be informed on the exact type of cladding including
        brand name used on your building, the amount (%) used in the total
        construction and location where used (schematics) as a minimum.
      </p>
      <p>
        You can find additional resources for Aluminium Composite Panelling at -
      </p>
      <div>
        <table class="w-100 execute-summary-table my-2 mb-0">
          <tr>
            <th>State</th>
            <th>Resource website</th>
          </tr>
          <tr>
            <td>New South Wales</td>
            <td>
              <a class="text-black" target="_blank"
                href="https://www.fairtrading.nsw.gov.au/trades-and-businesses/construction-andtrade-essentials/building-products/aluminium-composite-panel-ban"><i
                  class="fas fa-lg fa-external-link-alt"></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>Queensland</td>
            <td>
              <a class="text-black" target="_blank"
                href="https://www.qfes.qld.gov.au/buildingsafety/cladding/Pages/default.aspx"><i
                  class="fas fa-lg fa-external-link-alt"></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>Victoria</td>
            <td>
              <a class="text-black" target="_blank" href="https://www.vba.vic.gov.au/cladding">
                <i class="fas fa-lg fa-external-link-alt"></i>
              </a>
            </td>
          </tr>
          <tr>
            <td>ACT</td>
            <td>
              <a class="text-black" target="_blank"
                href="https://www.act.gov.au/majorprojectscanberra/act-cladding-program/property-owner-information">
                <i class="fas fa-lg fa-external-link-alt"></i>
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImportantNotices",
};
</script>
<style>
  .aligned-bullet {
    white-space: normal;
    overflow-wrap: break-word;
  }
</style>