<template>
  <div id="v-pills-contacts">
    <div class="contact">
      <div class="account-executive">
        <h5 class="font-weght-bold">Account Executive</h5>
        <div class="mb-5">
          <ul class="list-unstyled">
            <li class="mb-2">
              <span class="heading">Name</span>
              <span>{{ contactDetails.accountOwner }}</span>
            </li>
            <li class="mb-2">
              <span class="heading">Telephone</span>
              <span>{{ contactDetails.accountOwnerPhone }} </span>
            </li>
            <li class="mb-2">
              <span class="heading">Email</span>
              <span>{{ contactDetails.accountOwnerEmail }}</span>
            </li>
          </ul>
        </div>
        <h5 class="font-weght-bold">Claims Service</h5>
        <div class="mb-5 pt-4">
          <ul class="list-unstyled">
            <li class="mb-2">
              <span class="heading">Telephone</span>
              <span>02 8319 5670</span>
            </li>

            <li class="mb-2">
              <span class="heading">Email</span>
              <a href="mailto:claims@collectiveib.com.au"
                >claims@collectiveib.com.au</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Contacts",
  props: {
    contactDetails: Object,
  },
};
</script>
