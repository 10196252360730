<template>
  <div id="v-pills-home">
    <div class="mb-md-5">
      <p>
        Collective Insurance Brokers (CIB) are pleased to present our Broker
        Advice report for the upcoming period.
      </p>
    </div>
    <div class="row renewal-summary">
      <h5 class="text-uppercase text-center font-weight-bold col-md-6">
        Current policy
      </h5>
      <h5 class="text-uppercase text-center font-weight-bold col-md-6">
        Renewal
      </h5>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_buildingsuminsured.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">
              Building sum insured
            </h6>
            <span
              >{{
                renewalSummary.buildingSumInsured.current
                  | currency("$", ",", 0, ".", "front", false)
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_buildingsuminsured.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">
              Building sum insured
            </h6>
            <span
              >{{
                renewalSummary.buildingSumInsured.renewal
                  | currency("$", ",", 0, ".", "front", false)
              }}
            </span>
          </div>
          <span class="dot" v-if="renewalSummary.buildingSumInsured.change > 0">
            <span>&#8593;</span
            >{{
              getPercentChangeText(renewalSummary.buildingSumInsured.change)
            }}%
          </span>
          <span class="dot" v-if="renewalSummary.buildingSumInsured.change < 0">
            <span>&#8595;</span
            >{{
              getPercentChangeText(renewalSummary.buildingSumInsured.change)
            }}%
          </span>
        </div>
      </div>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_liabilitycover.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">Liability Cover</h6>
            <span>
              {{
                renewalSummary.liabilityCover.current
                  | currency("$", ",", 0, ".", "front", false)
              }}
            </span>
            <span></span>
          </div>
        </div>
      </div>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_liabilitycover.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">Liability Cover</h6>
            <span
              >{{
                renewalSummary.liabilityCover.renewal
                  | currency("$", ",", 0, ".", "front", false)
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon-totalpremiumpayable.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">
              Total Premium Payable
            </h6>
            <span
              >{{
                renewalSummary.totalPremiumPayable.current.total
                  | currency("$", ",", 2, ".", "front", false)
              }}
            </span>
            <br />
            <span v-if="renewalSummary.totalPremiumPayable.current.perLot">
              {{
                renewalSummary.totalPremiumPayable.current.perLot
                  | currency("$", ",", 2, ".", "front", false)
              }}
              per lot
            </span>
            <span v-else></span>
          </div>
        </div>
      </div>

      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_buildingsuminsured.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">
              Total Premium Payable
            </h6>
            <span
              >{{
                renewalSummary.totalPremiumPayable.renewal.total
                  | currency("$", ",", 2, ".", "front", false)
              }}
            </span>
            <br />
            <span v-if="renewalSummary.totalPremiumPayable.renewal.perLot">
              {{
                renewalSummary.totalPremiumPayable.renewal.perLot
                  | currency("$", ",", 2, ".", "front", false)
              }}
              per lot
            </span>
            <span v-else></span>
          </div>
          <span
            class="dot"
            v-if="renewalSummary.totalPremiumPayable.change > 0"
          >
            <span>&#8593;</span
            >{{
              getPercentChangeText(renewalSummary.totalPremiumPayable.change)
            }}%
          </span>
          <span class="dot" v-if="renewalSummary.buildingSumInsured.change < 0">
            <span>&#8595;</span
            >{{
              getPercentChangeText(renewalSummary.totalPremiumPayable.change)
            }}%
          </span>
        </div>
      </div>

      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_currentinsurer.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">Current Insurer</h6>
            <span>{{ renewalSummary.currentInsurer }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 py-3">
        <div class="summary-card text-center p-4">
          <div class="card-img">
            <img
              class="img-fluid"
              src="../images/icon_currentinsurer.png"
              alt=""
            />
          </div>
          <div class="card-body px-0 pb-0">
            <h6 class="text-uppercase font-weight-bold">Recommended Insurer</h6>
            <span>{{ renewalSummary.recommendedInsurer }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "SummaryRecommendation",

  props: {
    renewalSummary: Object,
  },

  methods: {
    getPercentChangeText: function (change) {
      if (change) {
        change = Math.abs(change);
        if (change < 1) {
          return "<1";
        } else {
          return _.round(change, 0);
        }
      }
    },
  },
};
</script>
<style></style>
