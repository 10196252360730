<template>
  <div id="v-pills-duty-disclosure">
    <div class="duty-disclosure">
      <p class="mb-3">
        Before you enter into a contract of general insurance with an insurer,
        you have a duty, under the Insurance Contracts Act 1984, to disclose to
        the insurer every matter that you know, or could reasonably be expected
        to know, is relevant to the insurer's decision whether to accept the
        risk of the insurance and, if so, on what terms. You have the same duty
        to disclose those matters to the insurer before you renew, extend, vary
        or reinstate a contract of general insurance.
      </p>
      <p class="mb-3">
        Your duty however does not require disclosure of any matter:
      </p>
      <ul style="list-style-type: disc; padding-left: 1.5em;">
        <li class="aligned-bullet">That diminishes the risk to be undertaken by the insurer.</li>
        <li class="aligned-bullet">That is of common knowledge.</li>
        <li class="aligned-bullet">
          That your insurer knows or, in the ordinary course of business,
          ought to know;
        </li>
        <li class="aligned-bullet">
          As to which compliance with your duty is waived by the insurer.
        </li>
      </ul>
      <p class="mb-4">
        If you fail to comply with your duty of disclosure, the insurer may be
        entitled to reduce its liability under the contract in respect of any
        claim, with possibility of complete cancellation of the insurance
        contract. If your non-disclosure is fraudulent, the insurer may avoid
        entering into the contract from the beginning.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "DutyOfDisclosure",
};
</script>
<style>
  .aligned-bullet {
    white-space: normal;
    overflow-wrap: break-word;
  }
</style>