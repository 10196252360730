import ApiService from "@/core/services/api.service";

const BrokerAdviceDataService = {
  serverUrl: `${window.location.origin}/services`,
  transactionId: null,
  userId: null,

  setServerUrl: function (url) {
    this.serverUrl = url;
  },

  init: function (transactionId, userId, token) {
    if (process.env.NODE_ENV === "development") {
      this.serverUrl = `http://localhost:3000/services`;
    }

    this.transactionId = transactionId;
    this.userId = userId;
    ApiService.saveTokenAndSetHeader(token);
  },

  getBrokerAdviceData: function () {
    return ApiService.get(
      `${this.serverUrl}/renewal/${this.transactionId}/broker-advice`,
      {
        params: { userId: this.userId },
      }
    );
  },

  saveBrokerAdviceData: function (brokerAdviceData) {
    return ApiService.post(
      `${this.serverUrl}/renewal/${this.transactionId}/broker-advice`,
      {
        brokerAdviceData: brokerAdviceData,
        userId: this.userId,
      }
    );
  },

  createBrokerAdvicePDF: function () {
    return ApiService.get(
      `${this.serverUrl}/renewal/${this.transactionId}/broker-advice/pdf`,
      {
        params: { userId: this.userId },
      }
    );
  },

  isManagerReviewer: function (userId) {
    return ApiService.get(
      `${this.serverUrl}/active-users/${userId}/is-manager-reviewer`,
      {
        params: { userId: this.userId },
      }
    );
  },
};

export default BrokerAdviceDataService;
