<template>
  <div>
    <div class="insurance-terms-condition">
      <div class="special-condition">
        <h5 class="text-uppercase font-weight-bold mt-2 mb-3">
          INSURER SPECIAL CONDITIONS
        </h5>
        <div v-for="(insurer, index) in insurers" :key="index">
          <p class="p-3 bg-dark-grey mb-2" v-if="insurer.conditions !== ''">
            <span class="h5">{{ insurer.name }}</span>
            <br />
            {{ insurer.conditions }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TermsAndConditions",
  props: {
    insurers: Array,
  },
};
</script>
