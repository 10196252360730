<template>
  <div id="v-pills-remuneration">
    <h6 class="font-weight-bold">Remuneration</h6>
    <div class="renumeration">
      <p class="mb-3">
        Strata Managers are required to disclose any insurance commission or
        remuneration derived from your insurance program, as required by the
        Strata Schemes Management Act 2015. The amount disclosed includes any
        past and future anticipated earnings.
      </p>
      <p class="mb-3">
        The income payable to your Strata Management Company for the placement
        of your insurance through our recommended insurer is
        {{
        fees.K_CurrentYearArIncome
        | currency("$", ",", 2, ".", "front", false)
        }}
        (excluding GST), in accordance with your strata management agreement.
      </p>
      <p class="mb-3">
        Collective Insurance Brokers will also charge a broker fee, which is
        essentially our service fee for the management of your plan's insurance.
        The following are key areas that we will assist the strata manager with
        throughout the term of your insurance:
      </p>
      <ul style="list-style-type: disc; padding-left: 1.5em;">
        <li class="aligned-bullet">
          Obtaining competitive quotations each year for insurance renewal
        </li>
        <li class="aligned-bullet">Negotiating of insurance premiums and excesses</li>
        <li class="aligned-bullet">Dedicated Account Manager to provide Insurance management</li>
        <li class="aligned-bullet">
          Dedicated Claims Manager to provide Claims Management Servicel
        </li>
        <li class="aligned-bullet">
          Risk and Insurance Management Advisor to provide assistance with
          general and policy related queries
        </li>
      </ul>
      <p class="mb-3">
        Collective Insurance Brokers Fee for the management of your insurance is
        {{
        fees.K_CurrentYearBrokerFee
        | currency("$", ",", 2, ".", "front", false)
        }}
        (excluding GST).
      </p>
      <p class="mb-5">
        We envisage your Strata Management Company's commission (if received),
        will likely increase by between 5% and 20% for the following 12 months,
        based on the current sums insured and market conditions.
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Renumeration",
  props: {
    fees: Object,
  },
};
</script>
<style>
  .aligned-bullet {
    white-space: normal;
    overflow-wrap: break-word;
  }
</style>