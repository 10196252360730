<template>
  <div>
    <div class="pre-renewal">
      <div v-if="renewalDeclarationReceived">
        <p class="mb-3">
          To ensure the Owners Corporation complies with their Duty of
          Disclosure obligations, a PreRenewal Declaration was sent to your
          strata manager for their completion.
        </p>
        <p class="mb-3">
          This form provides an opportunity to disclose issues such as Building
          defects, Works over $500,000, Fire Orders, WHS issues, Asbestos,
          presence of ACP and known circumstances that may lead to an office
          bearer's liability claim.
        </p>
        <p class="mb-5">
          If any of the following requires amendment or additional matters need
          to be disclosed, please contact our office immediately as pricing may
          change
        </p>
      </div>
      <div v-else>
        <p class="mb-3">
          To ensure the Owners Corporation complies with their Duty of
          Disclosure obligations, a PreRenewal Declaration was sent to your
          strata manager for their completion
        </p>
        <p class="mb-3">
          This form provides an opportunity to disclose issues such as Building
          defects, Works over $500,000, Fire Orders, WHS issues, Asbestos,
          presence of ACP and known circumstances that may lead to an office
          bearer's liability claim.
        </p>
        <p class="mb-3">
          Quotations obtained for your renewal have been based on the below
          information which has been taken from the completed declaration.
        </p>
        <p class="mb-3">
          If any of the following requires amendment or additional matters need
          to be disclosed, please contact our office immediately as pricing may
          change.
        </p>
        <div class="mb-5">
          <div v-if="declarationFormReceived" >
            <p>
              The completed Renewal Declaration form can be viewed <a :href="declarationLink">here</a>.
            </p>
          </div>
        </div>
        <div
          class="prerenewal-textarea mb-4"
          v-for="(item, i) in declaration"
          :key="i"
        >
          <p class="mb-3">
            <span class="font-weight-bold">{{ i + 1 }}. </span
            >{{ item.displayName }}
          </p>
          <textarea
            class="w-100 pl-3 pt-2"
            v-model="item.newValue"
            style="border: 1px solid grey; height: 40px"
            rows="1"
          ></textarea>

          <!-- Check if item.newValue is 'Yes' -->
          <div v-if="item.newValue === 'Yes'">
            <!-- Iterate through item.children -->
            <div
              v-for="(child, j) in item.children"
              :key="j"
            >
            <!-- Only render this block if child.displayName does not include 'Attach' -->
              <div v-if="!child.displayName.includes('Attach')">
                <p class="mt-3 mb-1">{{ child.displayName }}</p>
                <textarea
                  class="w-100 pl-3 pt-2"
                  v-model="child.newValue"
                  style="border: 1px solid grey; height: 80px"
                  rows="3" 
                ></textarea>
              </div>
              <div v-else-if="child.value !== null">
                  <p class="mt-3 mb-1 font-weight-bold">Attachment Provided</p>
                </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PreRenewalDeclaration",
  props: {
    declaration: Array,
    renewalDeclarationReceived: Boolean,
    declarationLink: String,
    declarationFormReceived: Boolean
  },
};
</script>
