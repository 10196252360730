<template>
  <div>
    <div class="insurer-market">
      <div class="insurer-market-details">
        <h5 class="mb-2 font-weight-bold mt-8">
          INSURANCE MARKET UPDATE
        </h5>
        <p class="mb-3 mt-8">
          The Strata Insurance Market has continued to experience difficult conditions throughout the year and major
          flooding losses in 2022 - 2023 have significantly impacted the entire insurance industry.
        </p>
        <p class="mb-3">
          Strata insurers have been forced to increase insurance premiums and narrowing their appetite in what they are
          able to provide quotations on. Properties with outstanding defects with no clear action plan will struggle to
          obtain renewal terms and quotations from alternative insurers.
        </p>
        <p class="mb-3">
          With the current market environment, we envisage that conditions will not improve in the short term and
          insurance premiums will continue to increase in 2023 and beyond.
        </p>
        <p class="mb-3">
          The following factors are currently affecting the insurance market:
        </p>
        <ul style="list-style-type: disc; padding-left: 1.5em;">
          <li class="aligned-bullet">
            <strong>Natural Disaster events</strong> - The 2022 flooding catastrophes in NSW and QLD (in excess of
            $5.5billion) have
            resulted in increased premiums even if the area has not been affected. Insurers are passing these costs onto
            the consumers to recover losses.
          </li>
          <li class="aligned-bullet">
            <strong>Claims Inflation (Increased costs of claims)</strong> - The cost of managing claims has increased
            significantly due
            to an escalation of construction costs (labour and materials), shortage of trades and supply chain delays.
          </li>
          <li class="aligned-bullet">
            <strong>Increased Reinsurance costs</strong> - To be able to pay out major claim events, Insurers often
            purchase
            "reinsurance" which is insurance for insurers. Due to the increase of weather-related claims losses,
            reinsurers are increasing their pricing and in turn, strata insurers have no choice but to increase
            premiums.
          </li>
          <li class="aligned-bullet">
            <strong>Inflation</strong> - Australia with the rest of the world is seeing records rates of inflation due
            to compounding
            effects of Covid-19, Russia-Ukraine war and supply chain disruptions along with severe weather-related
            losses.
          </li>
          <li class="aligned-bullet">
            <strong>Tightening of Underwriting Guidelines and Appetite</strong> - Due to the tightening of underwriting
            guidelines and
            appetite, we have seen many schemes be classified as "High Risk". If any of the below apply to your
            property, the building may be deemed "High Risk".
            <ul style="list-style-type: circle; padding-left: 1.5em;" class="mt-3">
              <li>Presence of Expanded Polystyrene Sheets and Aluminium Composite Panels</li>
              <li>Outstanding or unresolved defect or WH&S issues without an approved plan and funding for rectification
                works.</li>
              <li>Buildings with notices on building orders / Fire orders issued.</li>
              <li>A high claims loss ratio or substantial outstanding claims</li>
              <li>Vacant properties (applies to both residential and commercial strata)</li>
              <li>Location of the property that increases the overall risk exposure.</li>
              <li>Presence of Asbestos</li>
            </ul>
          </li>
        </ul>
        <p class="mb-3">
          As strata insurers are examining their renewal portfolios very carefully, they are asking more questions
          before they will consider offering renewal terms. The additional information insurers are requesting includes:
          <br><br>
        <ul style="list-style-type: disc; padding-left: 3em;">
          <li class="aligned-bullet">
            Building defect reports and plans of action for rectification of noted defects.
          </li>
          <li class="aligned-bullet">
            Asbestos reports and subsequent asbestos mitigation or removal if required.
          </li>
          <li class="aligned-bullet">
            Dilapidation reports and plans of action to rectify identified problems.
          </li>
          <li class="aligned-bullet">
            Current building valuation reports within the State legislative requirements. Some insurers will not accept
            valuations that are more than 3 years old.
          </li>
          <li class="aligned-bullet">
            Completion of insurers' risk requirements within the allocated timeframe. Some insurers are refusing to
            offer policy renewals unless the requirements have been met.
          </li>
        </ul>
        </p>
        <p class="mb-3">
          Impacts to your renewal if we cannot satisfy the insurers requirements:
          <br><br>
        <ul style="list-style-type: disc; padding-left: 3em;">
          <li class="aligned-bullet">
            Significant premium increases (in excess of 50%)
          </li>
          <li class="aligned-bullet">
            An increase in imposed excesses for properties with a high frequency of claims
          </li>
          <li class="aligned-bullet">
            Short termed renewal periods being offered by insurers where defects have been outstanding for a long period
            of time.
          </li>
          <li class="aligned-bullet">
            Special terms and conditions with specific timing requirements
          </li>
          <li class="aligned-bullet">
            Potentially a decline to offer renewal and or cancellation of the policy.
          </li>
        </ul>
        </p>
      </div>
      <div class="premium-increasing">
        <h5 class="mb-2 font-weight-bold mt-8">
          STRATA INSURANCE PREMIUM OUTLOOK
        </h5>
        <p class="mb-3">
          Insurers and market analysts are predicting the strata and community title Insurance market will continue
          hardening for the foreseeable future as the strata and community title insurers look to recover from their
          large losses over the recent years.
        </p>
        <p class="mb-3">
          This translates to between 20% to 40% increases for lower risk residential and commercial schemes.
        </p>
        <p class="mb-3">
          However, strata and community title properties with identified issues will see substantially higher premium
          rate rises as insurers price strata property insurance to reflect the risk and characteristics of the
          building.
        </p>
        <p class="mb-3">
          Rest assured however that the present Strata Insurance market conditions will not continue indefinitely. Once
          claim ratios improve, potential legislative measures are implemented, and sufficient capital flows back into
          the Strata Insurance market, it is likely rate increases will curtail and swing back towards softer market
          conditions.
        </p>
      </div>
      <div class="premium-increasing">
        <h5 class="mb-2 font-weight-bold mt-8">
          INSURANCE CYCLE
        </h5>
        <p class="mb-3">
          The Insurance Cycle refers to the changes in the insurance market conditions over a period of time, which can
          span several years.
        </p>
        <p class="mb-3">
          The Insurance Cycle refers to the changes in the insurance market conditions over a period of time, which can
          span several years.
        </p>
        <p class="mb-3">
          A hard market normally follows a serious of catastrophic events and increasing claims pay-outs. This is in
          turn leads to higher reinsurance costs, insurers reducing their capacity and becoming strict in their
          underwriting guidelines.
        </p>
        <p class="mb-3">
          Below is a diagram which details the insurance cycle. We are currently in a hard market.
        </p>
      </div>
      <div class="insurance-cycle mb-4">
        <img class="mb-10 img-fluid mt-4" src="../images/Insurancecyclediagram11.png" alt="" />
      </div>
      <!-- TODO: unsure if this is supposed to be here still or a leftover.. confirm -->
      <!-- <div class="up-date mb-4 mt-10">
        <img class="img-fluid w-100" src="../images/up-date.jpg" alt="" />
      </div> -->
      <div class="insurance-selection mb-5">
        <h5 class="mb-2 font-weight-bold mt-8">WHAT CAN WE DO TO MINIMISE OUR PREMIUM INCREASES ?</h5>
        <p class="mb-3">
          Committees of strata and community title schemes can assist with minimising premium increases. Any proactive
          steps in addressing issues such as defects, maintenance or safety issues and minimising claims costs will all
          assist when negotiating with insurers to offer the best available premiums.
        </p>
        <p class="mb-3">
          The Strata Insurance Market has continued to experience difficult conditions throughout the year and major
          flooding losses in 2022 have significantly impacted the entire insurance industry.
        </p>
        <p class="mb-3">
          Insurers have been forced to increase insurance premiums and narrowing their appetite in what they are able to
          provide quotations on. Properties with outstanding defects with no clear action plan will struggle to obtain
          renewal terms and quotations from alternative insurers.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InsuranceMarketUpdate",
};
</script>
<style>
  .aligned-bullet {
    white-space: normal;
    overflow-wrap: break-word;
  }
</style>